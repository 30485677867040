<template>
    <div class="flex flex-col-reverse lg:flex-row relative">
        <div class="lg:w-3/4">
            <article id="faqs">
                <h2>How to Get Help</h2>
                <ul>
                    <li>
                        If you have a question or need technical support, you
                        can
                        <a href="tel:+1 206 219-1246"
                            >Call: +1 (206) 219-1246</a
                        >
                    </li>
                    <li>
                        Live support via video room, phone and chat are
                        available during the following hours ET:
                        <ul>
                            <li>
                                <b>Wednesday 3/31 1 p.m. - 3 p.m.</b>
                            </li>
                            <li>
                                <b>Friday, 4/2 10 a.m. - 12 p.m.</b>
                            </li>
                            <li>
                                <b>Monday, 4/5 11 a.m. - 2 p.m.</b>
                            </li>
                            <li>
                                <b>Tuesday, 4/6 11 a.m. - 5 p.m. </b>
                            </li>
                            <li>
                                <b>Wednesday, 4/7 11 a.m. - 5 p.m.</b>
                            </li>
                        </ul>
                    </li>
                </ul>
            </article>

            <article id="login" class="mt-8">
                <h2>Logging In</h2>
                <p>
                    Since you are accessing these FAQs from within the
                    conference platform, we assume you were able to log in
                    successfully.
                </p>
                <ul>
                    <li>
                        <b>Can someone else log in for me?</b><br />
                        No, your login information is specific to you and should
                        be kept private. For security purposes, you can only be
                        logged in from 1 location at a time.
                    </li>
                    <li>
                        <b
                            >Can I access the platform before the event begins
                            to confirm my equipment will work?</b
                        >
                        <p>
                            Yes, attendees will be given access to the virtual
                            event platform beginning Wednesday, March 31.
                        </p>
                    </li>
                    <li>
                        <b
                            >A Help Desk meeting room will be available. Visit
                            the
                            <router-link
                                to="/sessions"
                                target="_blank"
                                rel="noopener noreferrer"
                                >AGENDA</router-link
                            >
                            tab to access.</b
                        >
                    </li>
                </ul>
            </article>

            <article id="attendee" class="mt-8">
                <h2>My Attendee Profile</h2>
                <b
                    >As an attendee, you can update your individual profile,
                    including a photo, bio and other information.</b
                >
                <ul>
                    <li>
                        Navigate to the
                        <router-link
                            to="/attendees"
                            target="_blank"
                            rel="noopener noreferrer"
                            >'Attendees'</router-link
                        >
                        tab
                    </li>
                    <li>Click “View My Profile”.</li>
                    <li>
                        You will be able to:
                        <ul>
                            <li>
                                Add an avatar image 600x600 square (.png or
                                .jpg)
                            </li>
                            <li>
                                Edit your profile, including:
                                <ul>
                                    <li>
                                        Email (You decide if you want this to
                                        display, by checking the “display email”
                                        box. It is NOT displayed by default)
                                    </li>
                                    <li>Title</li>
                                    <li>
                                        Phone(and decide if you want this to
                                        display, by checking the “display Phone
                                        number” box (it is NOT displayed by
                                        default)
                                    </li>
                                    <li>
                                        Add your social media account links;
                                        these will show up as icons on your
                                        profile
                                    </li>
                                    <li>Add a short bio</li>
                                    <li>Add info about your organization</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Things you cannot change:
                        <ul>
                            <li>Your name</li>
                            <li>The name of your organization</li>
                        </ul>
                    </li>
                    <li>
                        If you need to change either of these, please contact
                        FMI.
                    </li>
                </ul>
            </article>
            <article id="whatshouldido" class="mt-8">
                <h2>What should I do if...</h2>
                <ul>
                    <li>
                        <b>I cannot access a session?</b>
                        <ul>
                            <li>
                                Please click on the help icon on the bottom
                                right of your screen to chat live or go to the
                                <router-link
                                    to="/sessions"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >Help Desk</router-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>
                            I can’t hear/see other attendees in a private
                            meeting or they can’t hear/see me?
                        </b>
                        <ul>
                            <li>
                                Please click on the help icon on the bottom
                                right of your screen to chat live or go to the
                                <router-link
                                    to="/sessions"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >Help Desk</router-link
                                >
                            </li>
                        </ul>
                    </li>

                    <li>
                        <b>My audio/video is buffering.</b>
                        <ul>
                            <li>Check the speed on your internet connection</li>
                            <li>
                                Try turning off your camera. A camera can
                                consume a lot of bandwidth
                            </li>
                            <li>
                                Close all other applications running on your
                                device.
                            </li>
                            <li>
                                Make sure that your device is as close to the
                                router as possible. If possible, use a
                                hard-wired connection
                            </li>
                            <li>
                                Click on the help icon on the bottom right of
                                your screen to chat live.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b
                            >I hear an echo when I talk or from other
                            attendees.</b
                        >
                        <ul>
                            <li>Try muting and then unmuting.</li>
                            <li>
                                If possible, do not use the built-in microphone
                                and speakers on your laptop. Use an external
                                headset or speaker.
                            </li>
                        </ul>
                    </li>
                </ul>
            </article>
            <article id="directconnect" class="mt-10">
                <h2>Direct Connect</h2>
                <p>
                    Direct Connect opens for meeting requests on Tuesday, April
                    6, 2021 and is available throughout the official conference
                    days only.
                </p>
                <ul>
                    <li>
                        The
                        <router-link
                            to="/attendees"
                            target="_blank"
                            rel="noopener noreferrer"
                            >"ATTENDEES"</router-link
                        >
                        tab lets you search for attendees so you can requests a
                        meeting.
                    </li>
                    <li>
                        You can search for other attendees by first name, last
                        name, and organization.
                    </li>
                    <li>
                        Once you find the person you are looking for, click
                        “Direct Connect” to requests a meeting.
                    </li>
                    <li>
                        You will be able to invite up to 1 person for each
                        appointment.
                    </li>
                    <li>
                        Appointments are available on your
                        <router-link
                            to="/schedule"
                            target="_blank"
                            rel="noopener noreferrer"
                            >‘My Schedule’</router-link
                        >
                        page and will be held in a video room.
                    </li>
                    <li>
                        After you create the invitation, the people invited
                        will:
                        <ul>
                            <li>
                                See a message in the message center (if they are
                                not on the page, they will see a small number
                                next to the Message Center tab showing that
                                there is a message)
                            </li>
                            <li>
                                See the invitation on their
                                <router-link
                                    to="/schedule"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >‘My Schedule’</router-link
                                >
                                page.
                            </li>
                            <li>
                                Invitees can accept or decline (on My Schedule);
                                you will receive a message when an invitee
                                accepts or declines the invitation.
                            </li>
                            <li>
                                Invitees can send you a follow up message,
                                asking to move the time or for you to add people
                                to the meeting invitation
                            </li>
                            <li>As the host, you can:</li>
                            <ul>
                                <li>Cancel the meeting invitation</li>
                            </ul>
                        </ul>
                    </li>
                </ul>
            </article>
            <article id="sponsors" class="mt-10">
                <h2>SPONSORS</h2>

                <ul>
                    <li>
                        <b>Where can I find sponsor information?</b><br />
                        Click on the
                        <router-link
                            to="/tradeshow"
                            target="_blank"
                            rel="noopener noreferrer"
                            >"SPONSORS"</router-link
                        >
                        tab in the top menu to view additional information and
                        connect with the sponsors.
                    </li>
                </ul>
            </article>
            <article id="communicate" class="mt-8">
                <h2>Communicating With Other Participants</h2>
                <ul>
                    <li>
                        <b>Where can I see who is attending?</b><br />
                        Click on the
                        <router-link
                            to="/attendees"
                            target="_blank"
                            rel="noopener noreferrer"
                            >"ATTENDEES"</router-link
                        >
                        tab on the top menu bar. You can search for participants
                        by first name, last name, organization or state
                    </li>
                    <li>
                        <b>Are the private meetings being recorded?</b>
                        <ul>
                            <li>
                                No, neither the meeting itself, nor the chat
                                messages are recorded.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b
                            >Can I get a transcript of the chat in the private
                            meeting rooms?</b
                        >
                        <ul>
                            <li>No, the chat messages are not recorded.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Can I contact or message other attendees?</b>
                        <ul>
                            <li>
                                Yes, you can search for an attendee by first
                                name, last name, or company under the
                                <router-link
                                    to="/attendees"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >“ATTENDEES”</router-link
                                >
                                tab on the top menu bar. Then click on the “Send
                                a Message” button.
                            </li>
                        </ul>
                    </li>
                </ul>
            </article>
            <article id="troubleshooting" class="mt-8">
                <h2>Troubleshooting</h2>

                <ul>
                    <li>
                        <b
                            >How do I get help if I am having technical
                            issues?</b
                        >
                        <p>
                            There are several ways to get help if you are having
                            issues with the site.
                        </p>
                        <ul>
                            <li>
                                Go to the program page and click into the open
                                help desk session
                                <ul>
                                    <li>See above for help desk dates/times</li>
                                </ul>
                            </li>
                            <li>
                                Click on the help icon on the bottom right
                                corner or your screen to chat live with a
                                support person
                            </li>
                            <li>
                                call:
                                <a href="tel:+1 (206) 219-1246"
                                    >+1 (206) 219-1246</a
                                >
                            </li>
                            <li>
                                Email us
                                <a href="mailto:mailto:SCFHelp@fmi.org"
                                    >SCFHelp@fmi.org</a
                                >
                            </li>
                            <li>
                                Live technical support is available every day of
                                the conference from 11:00 AM - 5:00 pm Eastern
                                time.
                            </li>
                            <li>
                                What should i do if...
                                <ul>
                                    <li>
                                        I cannot access a private meeting?
                                        Please click on the
                                        <router-link
                                            to="/attendees"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            >“HELP”</router-link
                                        >
                                        tab on the top menu bar or click on the
                                        help icon on the bottom right of your
                                        screen to chat live or call the helpline
                                        <a href="tel:+1 206 219-1246"
                                            >Call: +1 (206) 219-1246</a
                                        >
                                    </li>
                                    <li>
                                        I can’t hear/see other attendees in a
                                        private meeting or they can’t hear/see
                                        me? Please click on the
                                        <router-link
                                            to="/attendees"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            >“HELP”</router-link
                                        >
                                        tab on the top menu bar or click on the
                                        help icon on the bottom right of your
                                        screen to chat live or call the helpline
                                        <a href="tel:+1 206 219-1246"
                                            >Call: +1 (206) 219-1246</a
                                        >
                                    </li>
                                    <li>
                                        My audio/video is buffering.
                                        <ol class="mt-0">
                                            <li>
                                                Check the speed on your internet
                                                connection
                                            </li>
                                            <li>
                                                Try turning off your camera. A
                                                camera can consume a lot of
                                                bandwidth
                                            </li>
                                            <li>
                                                Close all other applications
                                                running on your device.
                                            </li>
                                            <li>
                                                Make sure that your device is as
                                                close to the router as possible.
                                                If possible, use a hard-wired
                                                connection
                                            </li>
                                            <li>
                                                Click on the “Help” tab on the
                                                top menu bar or on the help icon
                                                on the bottom right of your
                                                screen to chat live.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        I hear an echo when I talk or from other
                                        attendees.
                                        <ol class="mt-0">
                                            <li>
                                                Try muting and then unmuting.
                                            </li>
                                            <li>
                                                If possible, do not use the
                                                built-in microphone and speakers
                                                on your laptop. Use an external
                                                headset or speaker.
                                            </li>
                                        </ol>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </article>
            <article id="practices" class="mt-8">
                <h2>Best Practices For Meeting Participation</h2>

                To ensure that your participation is as successful as possible,
                we recommend the following best practices:
                <ul>
                    <li>
                        <b id="equipment">Equipment</b>
                        <ul>
                            <li>
                                General requirements
                                <ul>
                                    <li>
                                        You will need access to either a PC,
                                        Mac, tablet, or smartphone. A PC or Mac
                                        is recommended. To participate in the
                                        meetings, it is recommended that you
                                        have an internal or external camera so
                                        that you can be seen on video.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                Microphones<br />
                                Clear audio can make a big difference.
                                Laptop/phone audio can work, but audio through
                                an external microphone (connected by wire or
                                Bluetooth) can be a huge plus. We recommend:
                                <ul>
                                    <li>
                                        Logitech USB Headset H390 with Noise
                                        Cancelling Mic
                                    </li>
                                    <li>
                                        Avid Education 1EDU-AE36WH-ITE Headphone
                                        with Boom Microphone, Single Plug, White
                                    </li>
                                </ul>
                            </li>

                            <li>
                                Browser
                                <ul>
                                    <li>
                                        The event will support the latest
                                        versions of Chrome, Safari, Firefox, and
                                        Edge. IE11 is not fully supported and
                                        you should use a different browser.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                Internet Connection Tips
                                <ul>
                                    <li>
                                        general, wired connections are better
                                        than wireless connections, and wireless
                                        connections are better than cellular
                                        based connections.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                While the event supports tablets and
                                smartphones, we recommend using a PC or Mac as
                                some functions will not work on a tablet or
                                phone.
                            </li>
                        </ul>
                    </li>

                    <li>
                        <b id="surroundings">Your Surroundings</b>
                        <ul>
                            <li>
                                Find a quiet area for participation. Avoid areas
                                with loud background noise (traffic, wind, loud
                                vents, or appliances).
                            </li>
                            <li>
                                Be one with the light and find the balance! Try
                                to avoid a dark room. Natural room sunlight is
                                good – just do not stand
                            </li>
                            <li>with the light shining right behind you.</li>
                            <li>
                                Consider the acoustics of the room. A small room
                                with dead acoustics will not flatter a voice.
                            </li>
                            <li>
                                Position yourself relatively close to the camera
                            </li>
                            <li>
                                Frame the camera so that your eyes are in the
                                top third of the frame.
                            </li>
                            <li>Use a plain, uncluttered background.</li>
                        </ul>
                    </li>

                    <li>
                        <b id="ScreenSharing">Screen Sharing</b>
                        <ul>
                            <li>
                                Use two monitors: one for the video chat; one
                                for the presentation
                            </li>
                            <li>
                                If sharing full-screen in Powerpoint, you
                                sometimes have to share out a separate window or
                                you’ll see the Presenter’s view
                            </li>
                            <li>
                                Or have another team member run the presentation
                            </li>
                            <li>Practice ahead of your presentation</li>
                        </ul>
                    </li>

                    <li>
                        <b id="meetingParticipation">Meeting Participation</b>
                        <ul>
                            <li>
                                We encourage participants to turn on their
                                camera in the private meeting rooms so that
                                other participants can see who is talking. But
                                please note that only 14 people can be on video
                                at once in the private meeting rooms.
                            </li>
                            <li>
                                Please mute yourself when not talking to avoid
                                background interruptions (barking dogs, phones
                                ringing, family members talking etc.)
                            </li>
                            <li>
                                Please review the
                                <a
                                    href="https://fmi-abc.s3.amazonaws.com/Virtual-Program-Code-of-Conduct.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >Code of Conduct for online meetings</a
                                >.
                            </li>
                        </ul>
                    </li>

                    <li>
                        <b id="unexpected">Prepare for the Unexpected</b>
                        <ul>
                            <li>
                                This is technology, some things *may* go wrong.
                            </li>
                            <li>
                                Have a back-up plan for if someone gets sick, if
                                someone’s internet connection drops, if a laptop
                                crashes, if someone’s audio goes haywire. Can
                                someone quickly take over the presentation?
                            </li>
                        </ul>
                    </li>
                </ul>
            </article>

            <h3>Have a Terrific Event! Thank you for participating!</h3>
        </div>
        <div class="lg:w-1/4 lg:pl-8">
            <ul>
                <li>
                    <a href="#faqs">How to Get Help</a>
                    <ul>
                        <li><a href="#login">Logging In</a></li>
                        <li><a href="#attendee">My Attendee Profile</a></li>
                        <li>
                            <a href="#whatshouldido">What should I do if...</a>
                        </li>
                        <li><a href="#directconnect">Direct Connect</a></li>
                        <li><a href="#sponsors">SPONSORS</a></li>
                        <li>
                            <a href="#communicate"
                                >Communicating With Other Participants</a
                            >
                        </li>
                        <li><a href="#troubleshooting">Troubleshooting</a></li>
                    </ul>
                </li>
                <li>
                    <a href="#practices"
                        >Best Practices For Meeting Participation</a
                    >
                    <ul>
                        <li>
                            <a href="#equipment">Equipment </a>
                        </li>
                        <li>
                            <a href="#surroundings">Your Surroundings</a>
                        </li>
                        <li>
                            <a href="#ScreenSharing">Screen Sharing</a>
                        </li>
                        <li>
                            <a href="#meetingParticipation"
                                >Meeting Participation</a
                            >
                        </li>
                        <li>
                            <a href="#unexpected">Prepare for the Unexpected</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "help-fmisc"
};
</script>

<style lang="scss" scoped>
@import "../../styles/setup/colors";
@import "../../styles/views/help";
</style>
